import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import { useUser } from "../../global_state";
import { UserButton } from "../navbar";

const Header = () => {
  const [user, setUser] = useUser();
  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <header className="flex items-center justify-between px-4 md:px-6 h-16 bg-white fixed top-0 w-full">
      <Link className="flex items-center gap-2" to="/">
        <img src={Logo} alt="Logo" width={24} />
        <span className="text-lg font-semibold">CaptureEcho</span>
      </Link>
      <nav className="hidden lg:flex items-center gap-4">
        <a
          className="text-sm font-medium hover:underline underline-offset-4"
          href="#hero"
        >
          Home
        </a>
        <a
          className="text-sm font-medium hover:underline underline-offset-4"
          href="#installation"
        >
          Installation
        </a>
        <a
          className="text-sm font-medium hover:underline underline-offset-4"
          href="#demo"
        >
          Demo
        </a>
        <a
          className="text-sm font-medium hover:underline underline-offset-4"
          href="#faqs"
        >
          FAQs
        </a>
        <a
          className="text-sm font-medium hover:underline underline-offset-4"
          href="#pricing"
        >
          Pricing
        </a>
        <a
          className="text-sm font-medium hover:underline underline-offset-4"
          href="#contact"
        >
          Contact
        </a>
      </nav>
      <div className="hidden lg:flex items-center gap-2">
        {user ? (
          <UserButton user={user} logout={logout} />
        ) : (
          <>
            <Link
              className="inline-flex h-9 items-center justify-center rounded-md bg-gray-900 px-4 py-2 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 bg-gradient-to-r from-[rgb(255,111,72)] to-[rgb(240,42,166)] text-gray-50 hover:opacity-90"
              to="/register/"
            >
              Get Started
            </Link>
            <Link
              className="inline-flex h-9 items-center justify-center rounded-md border border-gray-200 border-gray-200 bg-white px-4 py-2 text-sm font-medium shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50"
              to={"/login/"}
            >
              Login
            </Link>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
