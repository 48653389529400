import ReactPlayer from "react-player";
import HeroImage from "../../assets/img/hero-image.png";

const HeroSection = () => {
  return (
    <section
      className="w-full py-12 pt-24 sm:pt-24 md:py-24 lg:py-32 xl:py-48 flex justify-center"
      id="hero"
    >
      <div className="container px-4 md:px-6">
        <div className="grid gap-6 lg:grid-cols-[1fr_550px] lg:gap-12 xl:grid-cols-[1fr_650px]">
          <div className="space-y-4">
            <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl xl:text-7xl">
              Start capturing meaningful feedback in seconds
            </h1>
            <p className="max-w-[600px] text-gray-500 md:text-xl ">
              With CaptureEcho your users send feeback and{" "}
              <b>attach screenshots</b> without leaving the page.
            </p>
            <div className="flex flex-col gap-2 min-[400px]:flex-row">
              <a
                className="inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-8 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50  bg-gradient-to-r from-[rgb(255,111,72)] to-[rgb(240,42,166)] text-gray-50 hover:opacity-90"
                href="/register"
              >
                Get Started for Free
              </a>
              <a
                className="inline-flex h-10 items-center justify-center rounded-md border border-gray-200 border-gray-200 bg-white px-8 text-sm font-medium shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50"
                href="#demo"
              >
                Learn More
              </a>
            </div>
          </div>
          {/* <img
            src={HeroImage}
            width="650"
            height="450"
            alt="Hero"
            className="mx-auto aspect-[16/9] overflow-hidden rounded-xl object-cover object-center sm:w-full"
          /> */}
          <div className="aspect-video overflow-hidden rounded-xl">
            <ReactPlayer url="https://www.youtube.com/watch?v=oocAAagAjQI" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
