import { Routes, Route } from "react-router-dom";
import ReviewsPage from "./pages/reviews";
import WebsitesPage from "./pages/websites";
import SettingsPage from "./pages/settings";
import { Outlet, Navigate } from "react-router-dom";
import LandingPage from "./pages/landing";
import Login from "./pages/login";
import Register from "./pages/register";
import SubscribePage from "./pages/subscribe";

const PrivateRoutes = () => {
  const token = localStorage.getItem("auth_token");
  if (!token) {
    return <Navigate to="/login/" />;
  }
  return <Outlet />;
};

const SubscribedRoutes = () => {
  const hasSubscription = localStorage.getItem("has_subscription");
  if (!hasSubscription || hasSubscription === "false") {
    return <Navigate to="/subscribe/" />;
  }

  return <Outlet />;
};

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route path="/login/" element={<Login />} />
    <Route path="/register/" element={<Register />} />
    <Route element={<PrivateRoutes />} path="">
      <Route path="subscribe/" element={<SubscribePage />} />
      <Route element={<SubscribedRoutes />} path="/app/">
        <Route path="websites/" element={<WebsitesPage />} />
        <Route path="reviews/" element={<ReviewsPage />} />
        <Route path="settings/" element={<SettingsPage />} />
      </Route>
    </Route>
  </Routes>
);

export default AppRoutes;
