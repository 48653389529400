import { useState } from "react";

const FAQ = ({ question, answer }: { question: string; answer: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      data-state={isOpen ? "open" : "closed"}
      className="rounded-lg border bg-gray-100 p-4 shadow-sm"
    >
      <button
        type="button"
        aria-controls="radix-:r8:"
        aria-expanded={isOpen ? "true" : "false"}
        data-state={isOpen ? "open" : "closed"}
        className="flex w-full items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold">{question}</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="h-5 w-5 transition-all"
        >
          {isOpen ? (
            <path d="m6 15 6-6 6 6"></path>
          ) : (
            <path d="m6 9 6 6 6-6"></path>
          )}
        </svg>
      </button>
      {isOpen && (
        <div data-state={isOpen ? "open" : "closed"} id="radix-:r8:">
          {answer}
        </div>
      )}
    </div>
  );
};

const FAQsSection = () => {
  return (
    <section
      className="w-full py-12 md:py-24 lg:py-32 flex justify-center"
      id="faqs"
    >
      <div className="container px-4 md:px-6">
        <div className="space-y-4">
          <div className="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm">
            FAQs
          </div>
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            Frequently Asked Questions
          </h2>
          <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
            Find answers to the most common questions about Capture Echo.
          </p>
        </div>
        <div className="mt-8 space-y-4">
          <FAQ
            question="What is CaptureEcho?"
            answer="CaptureEcho is a SaaS feedback tool that helps you collect and analyse feedback from your customers."
          />
          <FAQ question="Can I use it to get feedback about localisation and UI issues?" answer="CaptureEcho is the perfect tool to get feedback about localisation and UI issues. 
          Your users can give you feedback about it very efficiently by including website screenshots in their feedback."/>
          <FAQ
            question="What features are included?"
            answer="CaptureEcho Starter plan includes 1 website, 500 reviews per month, and basic analytics the feedback activity."
          />
          <FAQ
            question="Can I customise the appearence of the tool?"
            answer="Yes, you can do some customization on the tool's appearance to match your branding. You just need to add a bit of CSS to override background color and fonts for the components you want to customise."
          />
          <FAQ
            question="How much technical knowledge is required?"
            answer="You need very basic knowledge to install the tool, follow the instructions in the installation section and you're all set."
          />
        </div>
      </div>
    </section>
  );
};

export default FAQsSection;
