const ContactSection = () => {
  return (
    <section
      className="w-full py-12 md:py-24 lg:py-32 flex justify-center"
      id="contact"
    >
      <div className="container px-4 md:px-6">
        <div className="space-y-4">
          <div className="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm">
            Contact
          </div>
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            Get in Touch
          </h2>
          <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
            We'd love to hear your feedback! You can send it to us using the
            "Submit Feedback" tab, or email us directly at &nbsp;
            <a
              className="font-medium underline"
              href="mailto:contact@captureecho.com"
            >
              contact@captureecho.com
            </a>
            .{"\n                "}
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
