import PricingSection from "../components/landing/pricing_section";
import Header from "../components/landing/header";
import HeroSection from "../components/landing/hero_section";
import InstallationSection from "../components/landing/installation_section";
import DemoSection from "../components/landing/demo_section";
import FAQsSection from "../components/landing/faq_section";
import ContactSection from "../components/landing/contact_section";
import GiveItATryImage from "../assets/img/giveitatry.png";

const LandingPage = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col min-h-[100dvh]">
        <HeroSection />
        {/* <DemoSection /> */}
        <InstallationSection />
        <FAQsSection />
        <PricingSection isLanding={true} />
        <ContactSection />
      </div>

      <img
        src={GiveItATryImage}
        alt="Give it a try"
        className="fixed top-1/2 right-5 max-h-[200px] -translate-y-1/2"
      />
    </>
  );
};

export default LandingPage;
