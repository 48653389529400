import PlaceholderImage from "../../assets/img/placeholder-2.png";

const InstallationSection = () => {
  return (
    <section
      className="w-full py-12 md:py-24 lg:py-32 flex justify-center"
      id="installation"
    >
      <div className="container px-4 md:px-6">
        <div className="grid gap-6 lg:grid-cols-[1fr_550px] lg:gap-12 xl:grid-cols-[1fr_650px]">
          <div className="space-y-4">
            <div className="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm">
              Installation
            </div>
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
              Get Up and Running in Seconds
            </h2>
            <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              We worked hard to make the SaaS product as easy as possible, add
              two lines to your HTML code and you're all set!
            </p>
            <div className="rounded-lg border bg-gray-100 p-4 font-mono text-sm shadow-sm">
              <div>
                {/* reduce opacity */}
                <div className="flex text-gray-400">{"<head>"}</div>
                <div className="flex text-gray-400 pl-4">{"..."}</div>
                <div className="flex pl-4 w-72 md:w-full overflow-x-auto">
                  {
                    '<link rel="stylesheet" href="https://captureecho.com/captureecho.min.css" />'
                  }
                  <br />
                  {"<script src='https://captureecho.com/captureecho.min.js'/>"}
                </div>
                <div className="flex text-gray-400">{"</head>"}</div>
              </div>
            </div>
          </div>
          <img
            src={PlaceholderImage}
            width="650"
            height="450"
            alt="Installation"
            className="mx-auto aspect-[16/9] overflow-hidden rounded-xl object-cover object-center sm:w-full"
          />
        </div>
      </div>
    </section>
  );
};
export default InstallationSection;
